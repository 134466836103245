// extracted by mini-css-extract-plugin
export const root = "blog-post-module--root--1jwJf";
export const title = "blog-post-module--title--2hLP_ typography-module--responsiveTitle1--2aak3";
export const loaderContainer = "blog-post-module--loaderContainer--3GCZx";
export const mainImage = "blog-post-module--mainImage--BRVCV";
export const grid = "blog-post-module--grid--2ruUr";
export const mainContent = "blog-post-module--mainContent--2HXDU";
export const metaContent = "blog-post-module--metaContent--24iVW";
export const publishedAt = "blog-post-module--publishedAt--2jGHB typography-module--small--2jpCI";
export const categories = "blog-post-module--categories--3hGXV";
export const categoriesHeadline = "blog-post-module--categoriesHeadline--2s6Dt typography-module--base--2DtHq";
export const buyMeACoffeeContainer = "blog-post-module--buyMeACoffeeContainer--2OCkG";